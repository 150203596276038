import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import ContractLineItem from "@/view/pages/partials/Line-Item-Detail-New";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import SendMail from "@/view/pages/partials/Send-Mail";
import InternalVisit from "@/view/pages/visit/Visit-Listing-Template";
import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import JwtService from "@/core/services/jwt.service";
export default {
  mixins: [CommonMixin],
  data: function data() {
    return {
      contractId: 0,
      pageLoaded: false,
      statusLoading: false,
      mail_dialog: false,
      invoiceLoading: false,
      contractTab: "other",
      moreActions: [],
      detail: {},
      dbLineItems: [],
      dbEquipments: []
    };
  },
  methods: {
    getLineItems: function getLineItems(data) {
      var _this = this;

      _this.$store.dispatch(QUERY, {
        url: "line-item/new",
        data: data
      }).then(function (_ref) {
        var data = _ref.data;

        if (data.length) {
          var result = [];

          for (var i = 0; i < data.length; i++) {
            result.push({
              id: data[i].id,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode
            });
          }

          _this.$nextTick(function () {
            _this.dbLineItems = _this.lodash.filter(result, function (row) {
              return row.product_type == "goods" || row.product_type == "service";
            });
            _this.dbEquipments = _this.lodash.filter(result, {
              product_type: "equipment"
            });
          });
        }
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    updateMoreAction: function updateMoreAction(type) {
      switch (type) {
        case "edit":
          this.editContract();
          break;

        case "revise":
          this.reviseContract();
          break;

        case "convert_visit":
          this.routeToJob();
          break;

        case "convert_invoice":
          this.routeToInvoice();
          break;

        case "duplicate":
          this.routeToDuplicate();
          break;

        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;

        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;

        case "send_as_email":
          this.mail_dialog = true;
          break;

        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;

        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;

        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;

        case "download_pdf":
          this.downloadPDF();
          break;

        case "print":
          this.printPDF();
          break;
      }
    },
    updateStatus: function updateStatus(status) {
      var _this2 = this;

      if (this.statusLoading) {
        return false;
      }

      this.statusLoading = true;
      this.$store.dispatch(PATCH, {
        url: "contract/" + this.contractId + "/status",
        data: {
          status: status
        }
      }).then(function () {
        _this2.getContract();
      }).catch(function (error) {
        _this2.logError(error);
      }).finally(function () {
        _this2.statusLoading = false;
      });
    },
    downloadPDF: function downloadPDF() {
      var id = this.detail.id;
      var token = JwtService.getToken();
      var url = "".concat(this.$apiURL, "contract/").concat(id, "/pdf/download?token=").concat(token);
      window.open(url, "_blank");
    },
    printPDF: function printPDF() {
      var id = this.detail.id;
      var token = JwtService.getToken();
      var url = "".concat(this.$apiURL, "contract/").concat(id, "/print?token=").concat(token);
      window.open(url, "_blank");
    },
    routeToJob: function routeToJob() {
      var _this$detail = this.detail,
          billing = _this$detail.billing,
          billing_person = _this$detail.billing_person,
          customer = _this$detail.customer,
          property = _this$detail.property,
          property_person = _this$detail.property_person;
      this.$router.push(this.getDefaultRoute("job.create", {
        query: {
          contract: this.contractId,
          customer: customer ? customer.id : null,
          property_contact_person: property_person ? property_person.id : null,
          billing_contact_person: billing_person ? billing_person.id : null,
          property: property ? property.id : null,
          billing: billing ? billing.id : null
        }
      }));
    },
    routeToInvoice: function routeToInvoice() {
      var _this$detail2 = this.detail,
          billing = _this$detail2.billing,
          billing_person = _this$detail2.billing_person,
          customer = _this$detail2.customer,
          property = _this$detail2.property,
          property_person = _this$detail2.property_person;
      this.$router.push(this.getDefaultRoute("invoice.create", {
        query: {
          contract: this.contractId,
          customer: customer ? customer.id : null,
          property_contact_person: property_person ? property_person.id : null,
          billing_contact_person: billing_person ? billing_person.id : null,
          property: property ? property.id : null,
          billing: billing ? billing.id : null
        }
      }));
    },
    routeToDuplicate: function routeToDuplicate() {
      var _this$detail3 = this.detail,
          billing = _this$detail3.billing,
          billing_person = _this$detail3.billing_person,
          customer = _this$detail3.customer,
          property = _this$detail3.property,
          property_person = _this$detail3.property_person;
      this.$router.push(this.getDefaultRoute("contract.create", {
        query: {
          customer: customer ? customer.id : null,
          billing: billing ? billing.id : null,
          property: property ? property.id : null,
          property_person: property_person ? property_person.id : null,
          billing_person: billing_person ? billing_person.id : null,
          duplicate: this.contractId
        }
      }));
    },
    reviseContract: function reviseContract() {
      var _this$detail4 = this.detail,
          billing = _this$detail4.billing,
          billing_person = _this$detail4.billing_person,
          customer = _this$detail4.customer,
          property = _this$detail4.property,
          property_person = _this$detail4.property_person;
      this.$router.push(this.getDefaultRoute("contract.create", {
        query: {
          customer: customer ? customer.id : null,
          billing: billing ? billing.id : null,
          property: property ? property.id : null,
          property_person: property_person ? property_person.id : null,
          billing_person: billing_person ? billing_person.id : null,
          revise: this.contractId
        }
      }));
    },
    editContract: function editContract() {
      var _this$detail5 = this.detail,
          billing = _this$detail5.billing,
          billing_person = _this$detail5.billing_person,
          customer = _this$detail5.customer,
          property = _this$detail5.property,
          property_person = _this$detail5.property_person;
      this.$router.push(this.getDefaultRoute("contract.update", {
        params: {
          id: this.contractId
        },
        query: {
          customer: customer ? customer.id : null,
          billing: billing ? billing.id : null,
          property: property ? property.id : null,
          property_person: property_person ? property_person.id : null,
          billing_person: billing_person ? billing_person.id : null
        }
      }));
    },
    getContract: function getContract() {
      var _this3 = this;

      this.$store.dispatch(GET, {
        url: "contract/" + this.contractId
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this3.detail = data;
        _this3.moreActions = data.more_actions;

        _this3.getLineItems({
          contract: _this3.detail.id
        });

        _this3.$store.dispatch(SET_BREADCRUMB, [{
          title: "Contract",
          route: "contract"
        }, {
          title: "Detail"
        }, {
          title: data.barcode
        }]);
      }).catch(function (error) {
        _this3.logError(error);
      }).finally(function () {
        _this3.pageLoaded = true;
      });
    }
  },
  created: function created() {
    this.contractId = this.lodash.toSafeInteger(this.$route.params.id);
  },
  mounted: function mounted() {
    this.getContract();
  },
  components: {
    CustomStatus: CustomStatus,
    FileTemplate: FileTemplate,
    DetailTemplate: DetailTemplate,
    InternalVisit: InternalVisit,
    SendMail: SendMail,
    InternalInvoice: InternalInvoice,
    ContractLineItem: ContractLineItem
  }
};